<template>
  <div class="sort-bar-component">
    <div
      v-for="(button, index) in sortButtons"
      :key="`sort-bar-button-${index}`"
      class="sort-bar__button"
      :class="{ 'sort-bar__button_active': Boolean(button.sortParam === String(selectedSortParam).replace('-', '')) }"
    >
      <div
        @click="selectSort(button.sortParam)"
        class="sort-bar__button-content"
      >
        <span>{{ button.name }}</span>
        <img
          v-if="Boolean(button.sortParam === String(selectedSortParam).replace('-', ''))"
          src="~/assets/img/filter/arrow-down.svg"
          :alt="`${button.name}`"
          :class="{ 'sort-bar__button-sort-image_rotate' : Boolean(String(selectedSortParam).includes('-'))}"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {useI18n} from "#imports";

const { t } = useI18n();

const emits = defineEmits<{
  selectSortParam: [name: string],
}>();

const sortButtons = computed(() => {
  return [
    {
      id: '',//'getSms',
      name: t('filterComponent.byQuantity'), //t('menu.getSms'),
      sortParam: 'phones',
      isShow: true
    },
    {
      id: '',//'earnMoney',
      name: t('filterComponent.byPrice'),//t('menu.earnMoney'),
      sortParam: 'price',
      isShow: true
    },
    // {
    //   id: '',
    //   name: '',//t('menu.doc'),
    //   sortParam: '',//localePath('/cabinet/api'),
    //   isShow: false,
    // }
  ];
});

const selectedSortParam = ref('');
const selectSort = function(name: string) {
  let _name = name
  if (Boolean(String(_name)?.[0] === '-')) {
    _name= name
  }
  if (Boolean(String(_name)?.[0] !== '-') && selectedSortParam.value === name) {
    _name = `-${name}`
  }
  emits('selectSortParam', String(_name));
  selectedSortParam.value = String(_name);
}
const isActive = computed(() => {

})
</script>

<style scoped lang="scss">
.sort-bar-component {
  display: flex;
  //justify-content: space-between;
  gap: 37px;
  width: 100%;
  background: rgba(251, 252, 252, 1);
  box-shadow: 5px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 11px 15px;
}
.sort-bar__button {
  color: rgba(69, 69, 69, 0.6);
  font-size: 13px;
  font-weight: 400;
  line-height: 13.6px;
  letter-spacing: -0.01em;
  cursor: pointer;
}
.sort-bar__button_active {
  color: rgba(69, 69, 69, 1);
  font-weight: 600;
}
.sort-bar__button-content {
  display: flex;
  gap: 5px;
}
.sort-bar__button-sort-image_rotate {
  transform: rotate(180deg);
}
</style>